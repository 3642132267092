import React, {FunctionComponent} from 'react';
import Stream from '../components/Stream';
import {useGetLikesQuery} from 'soundcloud-tracker-schema';
import {tracks2stream} from '../lib';

const Likes: FunctionComponent = () => {
    const {error, data: {likes = []} = {}, loading} = useGetLikesQuery();

    return error ? <div>Error! {error.message}</div> : <Stream stream={tracks2stream(likes)} loading={loading} />;
};

export default Likes;
