import React from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {getPlaylistByName, useCreatePlaylist, useGetPlaylistsQuery} from 'soundcloud-tracker-schema';
import classes from './VerifyPlaylists.module.css';

export default ({children}: any) => {
    const {error, data: {playlists = []} = {}, refetch, loading} = useGetPlaylistsQuery();

    const [exec, {loading: loadingPlaylist}] = useCreatePlaylist();

    if (loading) {
        return (
            <div className={classes.container}>
                <Icon spin icon={faSpinner} />
                &nbsp;Loading playlists...
            </div>
        );
    }
    if (error) {
        return <div>Error! {error.message}</div>;
    }

    const verifyPlaylist = (title) => {
        const playlist = getPlaylistByName(playlists, title);

        if (!playlist) {
            return (
                <>
                    <h2>{title} playlist not found</h2>

                    <p>
                        This app is using playlists to store information, so you need to create a new playlist with the
                        title <strong>&quot;{title}&quot;</strong>.
                    </p>

                    <p>
                        <button className="btn btn-primary" onClick={() => exec({variables: {title}})}>
                            {loadingPlaylist ? <Icon spin icon={faSpinner} /> : `Create ${title} playlist`}
                        </button>
                        {'  or  '}
                        <button className="btn btn-primary" onClick={() => refetch()}>
                            Reload playlists
                        </button>
                    </p>
                </>
            );
        }
    };

    return verifyPlaylist('Seen') || verifyPlaylist('Processed') || children;
};
