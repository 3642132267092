import React from 'react';
import {render} from 'react-dom';
import {isDev, isElectron} from './lib';
import {register, unregister} from './serviceWorker';
import Router from './Router';

import 'bootswatch/dist/lumen/bootstrap.css';
import './index.css';

const rootEl = document.getElementById('app');

render(<Router />, rootEl);

if (module.hot) {
    module.hot.accept('./Router', () => {
        const NextRouter = require('./Router').default;
        render(<NextRouter />, rootEl);
    });
}

(async () => {
    if (isElectron()) {
        return;
    }
    if (isDev) {
        await unregister();
    } else {
        await register();
    }
})();
