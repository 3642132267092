"use strict";
/* eslint-disable */
/* prettier-ignore-start */
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTrimPlaylistMutation = exports.TrimPlaylistDocument = exports.useCreatePlaylistMutation = exports.CreatePlaylistDocument = exports.useSetPlaylistMutation = exports.SetPlaylistDocument = exports.useSetLikeMutation = exports.SetLikeDocument = exports.useGetMeLazyQuery = exports.useGetMeQuery = exports.GetMeDocument = exports.useGetPlaylistsLazyQuery = exports.useGetPlaylistsQuery = exports.GetPlaylistsDocument = exports.useGetLikesLazyQuery = exports.useGetLikesQuery = exports.GetLikesDocument = exports.useGetStreamLazyQuery = exports.useGetStreamQuery = exports.GetStreamDocument = exports.PlaylistShortFragmentDoc = exports.PlaylistFragmentDoc = exports.TrackFragmentDoc = exports.UserFragmentDoc = exports.TrackShortFragmentDoc = exports.TrackType = exports.TrackState = exports.TrackLicense = exports.Sharing = exports.PlaylistType = exports.Embeddable = exports.ActivityType = void 0;
var client_1 = require("@apollo/client");
var ApolloReactHooks = __importStar(require("@apollo/client"));
var defaultOptions = {};
var ActivityType;
(function (ActivityType) {
    ActivityType["Comment"] = "comment";
    ActivityType["Playlist"] = "playlist";
    ActivityType["PlaylistRepost"] = "playlistRepost";
    ActivityType["Track"] = "track";
    ActivityType["TrackRepost"] = "trackRepost";
})(ActivityType = exports.ActivityType || (exports.ActivityType = {}));
var Embeddable;
(function (Embeddable) {
    Embeddable["All"] = "all";
    Embeddable["Me"] = "me";
    Embeddable["None"] = "none";
})(Embeddable = exports.Embeddable || (exports.Embeddable = {}));
var PlaylistType;
(function (PlaylistType) {
    PlaylistType["Album"] = "album";
    PlaylistType["Archive"] = "archive";
    PlaylistType["Compilation"] = "compilation";
    PlaylistType["Demo"] = "demo";
    PlaylistType["EsSingle"] = "esSingle";
    PlaylistType["Other"] = "other";
    PlaylistType["ProjectFiles"] = "projectFiles";
    PlaylistType["Recording"] = "recording";
    PlaylistType["SamplePack"] = "samplePack";
    PlaylistType["Showcase"] = "showcase";
})(PlaylistType = exports.PlaylistType || (exports.PlaylistType = {}));
var Sharing;
(function (Sharing) {
    Sharing["Private"] = "private";
    Sharing["Public"] = "public";
})(Sharing = exports.Sharing || (exports.Sharing = {}));
var TrackLicense;
(function (TrackLicense) {
    TrackLicense["AllRightsReserved"] = "allRightsReserved";
    TrackLicense["CcBy"] = "ccBy";
    TrackLicense["CcByNc"] = "ccByNc";
    TrackLicense["CcByNcNd"] = "ccByNcNd";
    TrackLicense["CcByNcSa"] = "ccByNcSa";
    TrackLicense["CcByNd"] = "ccByNd";
    TrackLicense["CcBySa"] = "ccBySa";
    TrackLicense["NoRightsReserved"] = "noRightsReserved";
})(TrackLicense = exports.TrackLicense || (exports.TrackLicense = {}));
var TrackState;
(function (TrackState) {
    TrackState["Failed"] = "failed";
    TrackState["Finished"] = "finished";
    TrackState["Processing"] = "processing";
})(TrackState = exports.TrackState || (exports.TrackState = {}));
var TrackType;
(function (TrackType) {
    TrackType["Demo"] = "demo";
    TrackType["InProgress"] = "inProgress";
    TrackType["Live"] = "live";
    TrackType["Loop"] = "loop";
    TrackType["Original"] = "original";
    TrackType["Other"] = "other";
    TrackType["Podcast"] = "podcast";
    TrackType["Recording"] = "recording";
    TrackType["Remix"] = "remix";
    TrackType["Sample"] = "sample";
    TrackType["SoundEffect"] = "soundEffect";
    TrackType["Spoken"] = "spoken";
    TrackType["Stem"] = "stem";
})(TrackType = exports.TrackType || (exports.TrackType = {}));
exports.TrackShortFragmentDoc = client_1.gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    fragment TrackShort on TrackShort {\n  id\n}\n    "], ["\n    fragment TrackShort on TrackShort {\n  id\n}\n    "])));
exports.UserFragmentDoc = client_1.gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    fragment User on UserShort {\n  username\n  avatar_url\n  permalink\n}\n    "], ["\n    fragment User on UserShort {\n  username\n  avatar_url\n  permalink\n}\n    "])));
exports.TrackFragmentDoc = client_1.gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    fragment Track on Track {\n  id\n  user {\n    ...User\n  }\n  title\n  artwork_url\n  permalink_url\n  created_at\n  duration\n  is_liked\n  is_seen\n  is_processed\n}\n    ", ""], ["\n    fragment Track on Track {\n  id\n  user {\n    ...User\n  }\n  title\n  artwork_url\n  permalink_url\n  created_at\n  duration\n  is_liked\n  is_seen\n  is_processed\n}\n    ", ""])), exports.UserFragmentDoc);
exports.PlaylistFragmentDoc = client_1.gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    fragment Playlist on Playlist {\n  id\n  title\n  track_count\n  artwork_url\n  permalink_url\n  user {\n    ...User\n  }\n  tracks {\n    ...Track\n  }\n}\n    ", "\n", ""], ["\n    fragment Playlist on Playlist {\n  id\n  title\n  track_count\n  artwork_url\n  permalink_url\n  user {\n    ...User\n  }\n  tracks {\n    ...Track\n  }\n}\n    ", "\n", ""])), exports.UserFragmentDoc, exports.TrackFragmentDoc);
exports.PlaylistShortFragmentDoc = client_1.gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    fragment PlaylistShort on PlaylistShort {\n  id\n  title\n  tracks {\n    ...Track\n  }\n}\n    ", ""], ["\n    fragment PlaylistShort on PlaylistShort {\n  id\n  title\n  tracks {\n    ...Track\n  }\n}\n    ", ""])), exports.TrackFragmentDoc);
exports.GetStreamDocument = client_1.gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    query getStream($futureCursor: String, $nextCursor: String) {\n  stream(futureCursor: $futureCursor, nextCursor: $nextCursor) {\n    next_cursor\n    future_cursor\n    collection {\n      type\n      origin_track {\n        ...Track\n      }\n      origin_playlist {\n        ...Playlist\n      }\n    }\n  }\n}\n    ", "\n", ""], ["\n    query getStream($futureCursor: String, $nextCursor: String) {\n  stream(futureCursor: $futureCursor, nextCursor: $nextCursor) {\n    next_cursor\n    future_cursor\n    collection {\n      type\n      origin_track {\n        ...Track\n      }\n      origin_playlist {\n        ...Playlist\n      }\n    }\n  }\n}\n    ", "\n", ""])), exports.TrackFragmentDoc, exports.PlaylistFragmentDoc);
/**
 * __useGetStreamQuery__
 *
 * To run a query within a React component, call `useGetStreamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStreamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStreamQuery({
 *   variables: {
 *      futureCursor: // value for 'futureCursor'
 *      nextCursor: // value for 'nextCursor'
 *   },
 * });
 */
function useGetStreamQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(exports.GetStreamDocument, options);
}
exports.useGetStreamQuery = useGetStreamQuery;
function useGetStreamLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(exports.GetStreamDocument, options);
}
exports.useGetStreamLazyQuery = useGetStreamLazyQuery;
exports.GetLikesDocument = client_1.gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    query getLikes {\n  likes {\n    ...Track\n  }\n}\n    ", ""], ["\n    query getLikes {\n  likes {\n    ...Track\n  }\n}\n    ", ""])), exports.TrackFragmentDoc);
/**
 * __useGetLikesQuery__
 *
 * To run a query within a React component, call `useGetLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLikesQuery({
 *   variables: {
 *   },
 * });
 */
function useGetLikesQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(exports.GetLikesDocument, options);
}
exports.useGetLikesQuery = useGetLikesQuery;
function useGetLikesLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(exports.GetLikesDocument, options);
}
exports.useGetLikesLazyQuery = useGetLikesLazyQuery;
exports.GetPlaylistsDocument = client_1.gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    query getPlaylists {\n  playlists {\n    ...PlaylistShort\n  }\n}\n    ", ""], ["\n    query getPlaylists {\n  playlists {\n    ...PlaylistShort\n  }\n}\n    ", ""])), exports.PlaylistShortFragmentDoc);
/**
 * __useGetPlaylistsQuery__
 *
 * To run a query within a React component, call `useGetPlaylistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaylistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaylistsQuery({
 *   variables: {
 *   },
 * });
 */
function useGetPlaylistsQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(exports.GetPlaylistsDocument, options);
}
exports.useGetPlaylistsQuery = useGetPlaylistsQuery;
function useGetPlaylistsLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(exports.GetPlaylistsDocument, options);
}
exports.useGetPlaylistsLazyQuery = useGetPlaylistsLazyQuery;
exports.GetMeDocument = client_1.gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    query getMe {\n  me {\n    ...User\n  }\n}\n    ", ""], ["\n    query getMe {\n  me {\n    ...User\n  }\n}\n    ", ""])), exports.UserFragmentDoc);
/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
function useGetMeQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useQuery(exports.GetMeDocument, options);
}
exports.useGetMeQuery = useGetMeQuery;
function useGetMeLazyQuery(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useLazyQuery(exports.GetMeDocument, options);
}
exports.useGetMeLazyQuery = useGetMeLazyQuery;
exports.SetLikeDocument = client_1.gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    mutation setLike($trackId: String!, $state: Boolean!) {\n  setLike(trackId: $trackId, state: $state) {\n    ...Track\n  }\n}\n    ", ""], ["\n    mutation setLike($trackId: String!, $state: Boolean!) {\n  setLike(trackId: $trackId, state: $state) {\n    ...Track\n  }\n}\n    ", ""])), exports.TrackFragmentDoc);
/**
 * __useSetLikeMutation__
 *
 * To run a mutation, you first call `useSetLikeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLikeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLikeMutation, { data, loading, error }] = useSetLikeMutation({
 *   variables: {
 *      trackId: // value for 'trackId'
 *      state: // value for 'state'
 *   },
 * });
 */
function useSetLikeMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useMutation(exports.SetLikeDocument, options);
}
exports.useSetLikeMutation = useSetLikeMutation;
exports.SetPlaylistDocument = client_1.gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    mutation setPlaylist($type: String!, $trackId: String!, $state: Boolean!) {\n  setPlaylist(type: $type, trackId: $trackId, state: $state) {\n    ...Track\n  }\n}\n    ", ""], ["\n    mutation setPlaylist($type: String!, $trackId: String!, $state: Boolean!) {\n  setPlaylist(type: $type, trackId: $trackId, state: $state) {\n    ...Track\n  }\n}\n    ", ""])), exports.TrackFragmentDoc);
/**
 * __useSetPlaylistMutation__
 *
 * To run a mutation, you first call `useSetPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPlaylistMutation, { data, loading, error }] = useSetPlaylistMutation({
 *   variables: {
 *      type: // value for 'type'
 *      trackId: // value for 'trackId'
 *      state: // value for 'state'
 *   },
 * });
 */
function useSetPlaylistMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useMutation(exports.SetPlaylistDocument, options);
}
exports.useSetPlaylistMutation = useSetPlaylistMutation;
exports.CreatePlaylistDocument = client_1.gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n    mutation createPlaylist($title: String!) {\n  createPlaylist(title: $title) {\n    ...Playlist\n  }\n}\n    ", ""], ["\n    mutation createPlaylist($title: String!) {\n  createPlaylist(title: $title) {\n    ...Playlist\n  }\n}\n    ", ""])), exports.PlaylistFragmentDoc);
/**
 * __useCreatePlaylistMutation__
 *
 * To run a mutation, you first call `useCreatePlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlaylistMutation, { data, loading, error }] = useCreatePlaylistMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
function useCreatePlaylistMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useMutation(exports.CreatePlaylistDocument, options);
}
exports.useCreatePlaylistMutation = useCreatePlaylistMutation;
exports.TrimPlaylistDocument = client_1.gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n    mutation trimPlaylist($type: String!) {\n  trimPlaylist(type: $type) {\n    ...Playlist\n  }\n}\n    ", ""], ["\n    mutation trimPlaylist($type: String!) {\n  trimPlaylist(type: $type) {\n    ...Playlist\n  }\n}\n    ", ""])), exports.PlaylistFragmentDoc);
/**
 * __useTrimPlaylistMutation__
 *
 * To run a mutation, you first call `useTrimPlaylistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrimPlaylistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trimPlaylistMutation, { data, loading, error }] = useTrimPlaylistMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
function useTrimPlaylistMutation(baseOptions) {
    var options = __assign(__assign({}, defaultOptions), baseOptions);
    return ApolloReactHooks.useMutation(exports.TrimPlaylistDocument, options);
}
exports.useTrimPlaylistMutation = useTrimPlaylistMutation;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13;
