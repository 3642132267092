import cx from 'classnames';
import classes from './Image.module.css';
import React from 'react';

const Image = ({src, duplicate = false}) => (
    <div
        className={cx(classes.image, {[classes.missing]: !src, [classes.duplicate]: !!duplicate})}
        style={{backgroundImage: `url(${src})`}}
    />
);

export default Image;
