// @see https://developers.soundcloud.com/docs/api/guide#authentication
//TODO Object.fromEntries(new URLSearchParams(location.search))
//TODO new URLSearchParams({...}).toString();

require('isomorphic-unfetch');

const apiUri = 'https://api.soundcloud.com';
const accessTokenUri = apiUri + '/oauth2/token';
const authorizationUri = apiUri + '/connect';

const REDIRECT_URI = window.location.origin + '/redirect';

const isDev = window.location.origin.includes('localhost');

const CLIENT_ID = isDev ? process.env.REACT_APP_CLIENT_ID_DEV : process.env.REACT_APP_CLIENT_ID_PRO;
const CLIENT_SECRET = isDev ? process.env.REACT_APP_CLIENT_SECRET_DEV : process.env.REACT_APP_CLIENT_SECRET_PRO;

module.exports.isDev = isDev;

module.exports.apiUri = apiUri;

/**
 * @param state
 * @returns {string}
 */
module.exports.getLoginURL = (state) =>
    authorizationUri +
    '?' +
    new URLSearchParams({
        client_id: CLIENT_ID,
        display: 'popup',
        redirect_uri: REDIRECT_URI,
        response_type: 'code',
        state: JSON.stringify(state),
    });

/**
 * @param {string} code
 * @returns {Promise<{expires_at, state, access_token, token_type, scope, refresh_token, expires_in}>}
 */
module.exports.getTokenFromCode = (code) => {
    return fetch(accessTokenUri, {
        headers: {
            Accept: 'application/json; charset=utf-8',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        method: 'POST',
        body: new URLSearchParams({
            grant_type: 'authorization_code',
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            redirect_uri: REDIRECT_URI,
            code,
        }).toString(),
    })
        .then((res) => res.json())
        .then((json) => {
            console.log('TOKEN REDIRECT', json);
            return {
                ...json,
                expires_at: Date.now() + json.expires_in * 1000,
            };
        });
};
