import React, {FunctionComponent} from 'react';
import cx from 'classnames';
import {Playlist} from 'soundcloud-tracker-schema';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import classes from './Playlist.module.css';
import streamClasses from './Stream.module.css';
import trackClasses from './Track.module.css';
import Image from './Image';
import Track from './Track';

interface PlaylistItemProps {
    playlist: Playlist;
}

const PlaylistCmp: FunctionComponent<PlaylistItemProps> = ({playlist}) => (
    <>
        <div className={cx(streamClasses.entry, classes.playlist)}>
            <Image src={playlist.artwork_url} />

            <div className={cx(trackClasses.title, classes.title)}>
                {playlist.title}
                <a
                    href={playlist.permalink_url}
                    className={trackClasses.permalink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Icon icon={faExternalLinkAlt} />
                </a>
                {playlist.track_count !== playlist.tracks.length && (
                    <span className={cx('badge', 'badge-danger', trackClasses.badge)}>
                        CUT {playlist.tracks.length}/{playlist.track_count}
                    </span>
                )}
            </div>
        </div>

        {playlist.tracks.length ? (
            playlist.tracks.map((track, i) => <Track key={track.id} track={track} isInPlaylist />)
        ) : (
            <div className={cx(streamClasses.entry, trackClasses.inPlaylist)}>No tracks, check API</div>
        )}
    </>
);

export default PlaylistCmp;
