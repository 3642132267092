import {useEffect, useState} from 'react';
import {getClient} from 'soundcloud-tracker-schema';

const schema = require('soundcloud-tracker-schema/src/schema/schema.graphqls'); //eslint-disable-line

export const useClient = () => {
    const [client, setClient] = useState();
    useEffect(() => {
        fetch(schema)
            .then((res) => res.text())
            .then((schema) => setClient(getClient(schema as any) as any));
    }, []);
    return client;
};
