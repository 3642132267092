import React, {FC} from 'react';
import cx from 'classnames';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import {Link, Route, Routes, useLocation} from 'react-router-dom';
import {useGetMeQuery} from 'soundcloud-tracker-schema';
import VerifyPlaylists from '../components/VerifyPlaylists';
import classes from './App.module.css';
import Index from './Index';
import Likes from './Likes';
import Maintenance from './Maintenance';
import {useToken} from '../lib/token';
import {withAuth} from '../lib/login';

const NavLink = ({to, children}) => {
    const {pathname} = useLocation();
    return (
        <li
            className={cx('nav-item', {
                active: pathname === to,
            })}
        >
            <Link className="nav-link" to={to}>
                {children}
            </Link>
        </li>
    );
};

const App: FC = () => {
    const {data: {me = null} = {}, loading, error} = useGetMeQuery();
    const {deleteToken} = useToken();

    if (loading) {
        return <div>Loading user...</div>;
    }

    if (error) {
        return <div>{error.message}</div>;
    }

    const logout = (e) => {
        e.preventDefault();
        deleteToken();
    };

    return (
        <div className={classes.container}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <Link className="navbar-brand" to="/">
                    <img src={me.avatar_url} alt={me.username} /> {me.username}
                </Link>

                <ul className="navbar-nav">
                    <NavLink to="/app">Stream</NavLink>
                    <NavLink to="/app/likes">Likes</NavLink>
                    <NavLink to="/app/maintenance">Maintenance</NavLink>
                </ul>

                <div className="pull-right">
                    <button className="btn btn-danger" onClick={logout}>
                        <Icon icon={faSignOutAlt} /> Logout
                    </button>
                </div>
            </nav>

            <VerifyPlaylists>
                <Routes>
                    <Route path="likes" element={<Likes />} />
                    <Route path="maintenance" element={<Maintenance />} />
                    <Route index element={<Index />} />
                </Routes>
            </VerifyPlaylists>
        </div>
    );
};

export default withAuth({enforce: true})(App);
