import React, {FunctionComponent} from 'react';
import {useTrimPlaylistMutation} from 'soundcloud-tracker-schema';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

const Maintenance: FunctionComponent = () => {
    const [exec, {loading, error}] = useTrimPlaylistMutation();

    return (
        <div className="container">
            <h1>Seen</h1>
            {error && <div>Error! {error.message}</div>}
            <button disabled={loading} className="btn btn-primary" onClick={(e) => exec({variables: {type: 'seen'}})}>
                {loading ? <Icon spin icon={faSpinner} /> : 'Perform'}
            </button>
            <h1>Processed</h1>
            {error && <div>Error! {error.message}</div>}
            <button
                disabled={loading}
                className="btn btn-primary"
                onClick={(e) => exec({variables: {type: 'processed'}})}
            >
                {loading ? <Icon spin icon={faSpinner} /> : 'Perform'}
            </button>
        </div>
    );
};

export default Maintenance;
