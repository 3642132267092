import isElectron from 'is-electron';

export {isElectron};

export {isDev} from 'soundcloud-tracker-auth';

export const tracks2stream = (tracks = []): any => ({
    collection: tracks.map((track) => ({
        type: 'track',
        origin_track: track,
    })),
});

export const getCurrentId = (location): string => {
    const {id} = parseQueryString(location.search.replace('?', ''));
    return (id as string) || null;
};

export const copy = (node) => {
    window.getSelection().removeAllRanges();

    const range = document.createRange();

    range.selectNode(node);

    window.getSelection().addRange(range);

    try {
        document.execCommand('copy');
    } catch (err) {
        console.error('Unable to copy', err);
    }

    window.getSelection().removeAllRanges();
};

export const parseQueryString = (str: string) => Object.fromEntries(new URLSearchParams(str));
