import React from 'react';
import {Provider} from 'soundcloud-tracker-schema';
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';

import Login from './pages/Login';
import NoMatch from './pages/NoMatch';
import {RedirectLanding} from './lib/login';
import App from './pages/App';
import {useClient} from './lib/client';

export default function AppRoutes() {
    const client = useClient();
    if (!client) {
        return <div>Loading client...</div>;
    }
    return (
        <Provider client={client}>
            <BrowserRouter>
                <Routes>
                    <Route path="/redirect" element={<RedirectLanding />} />
                    <Route path="/access_token/*" element={<RedirectLanding />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/app/*" element={<App />} />
                    <Route path="/" element={<Navigate to="/app" />} />
                    <Route path="*" element={<NoMatch />} />
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}
