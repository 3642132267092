import {setAuthHeader, authListener} from 'soundcloud-tracker-schema';
import {useEffect, useState} from 'react';
import EventListener from 'events';

const localListener = new EventListener(); // LS events from current tab are not appearing on window by default

const STORAGE_KEY = 'soundcloud-token';

const setToken = (token) => {
    console.log('Set token', token);
    localStorage.setItem(STORAGE_KEY, token);
    localListener.emit('storage');
};

//TODO Clear Apollo cache
const deleteToken = () => {
    console.log('Delete token');
    localStorage.removeItem(STORAGE_KEY);
    localListener.emit('storage');
};

const getToken = () => localStorage.getItem(STORAGE_KEY) || '';

export const useToken = () => {
    const [access_token, setAccessToken] = useState(getToken());

    setAuthHeader(access_token);

    useEffect(() => {
        const listener = () => {
            const token = getToken();
            console.log('Listener', token);
            setAccessToken(token);
        };

        localListener.on('storage', listener);
        window.addEventListener('storage', listener);

        return () => {
            window.removeEventListener('storage', listener);
            localListener.off('storage', listener);
        };
    }, []);

    useEffect(() => {
        authListener.on('unauthorized', deleteToken);
        return () => {
            authListener.off('unauthorized', deleteToken);
        };
    });

    return {access_token, setToken, deleteToken};
};
