import React, {useEffect} from 'react';
import {useToken} from './token';
import {Navigate, useLocation} from 'react-router-dom';
import {parseQueryString} from './index';
import {getTokenFromCode, getLoginURL} from 'soundcloud-tracker-auth';
import cogoToast from 'cogo-toast';

export const openSoundCloudLogin = (state) => window.location.assign(getLoginURL(state));

export const withAuth =
    ({enforce = false}: {enforce: boolean} = {enforce: false}) =>
    (Cmp) =>
    () => {
        const {state, pathname} = useLocation();
        const {access_token} = useToken();

        if (!access_token && enforce) {
            return <Navigate to="/login" state={{from: pathname}} />;
        }

        const {from} = (state as any) || {from: {pathname: '/'}};

        if (access_token && !enforce) {
            return <Navigate to={from} />;
        }

        return <Cmp />;
    };

export const RedirectLanding = () => {
    const {error, error_description, state} = useGetRedirectData();
    const {access_token} = useToken();

    let to: string | any = '/login';

    if (error) {
        to += '?' + new URLSearchParams({error, error_description}).toString();
    }
    if (state && state !== 'undefined') {
        to = {pathname: to, state};
    }

    if (!access_token) {
        return <div>Loading...</div>;
    }

    return <Navigate to={to} />;
};

export const useGetRedirectData = () => {
    const {access_token, setToken} = useToken();
    const {hash, search, state: stateFromRouter} = useLocation();

    const hashParts = parseQueryString(hash.replace('#', ''));
    const searchParts = parseQueryString(search.replace('?', ''));

    const combined = {...hashParts, ...searchParts};

    const {error, error_description, code, state: stateFromUrl} = combined;

    let state = stateFromRouter;
    if (stateFromUrl && stateFromUrl !== 'undefined') {
        state = JSON.parse(stateFromUrl as string);
    }

    useEffect(() => {
        if (!code) {
            return;
        }
        (async () => {
            try {
                setToken((await getTokenFromCode(code)).access_token); //TODO Refresh token
            } catch (e) {
                console.error(e);
                cogoToast.error(e.message);
            }
        })();
    }, [code, setToken]);

    return {error, error_description, state, access_token};
};
