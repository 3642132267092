"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var lib_1 = require("./lib");
var mutex = new lib_1.Mutex();
var limit = 10000;
var maxEntries = 500;
var regex = /[ :_-]([a-z])/g;
var convertId = function (fieldName) { return (fieldName || '').replace(regex, function (v) { return v[1].toUpperCase(); }); };
var getQuery = function (url) { return new URLSearchParams(url ? new URL(url).search : ''); };
var likesPromise;
var playlistsPromise;
var simplifyTracks = function (tracks) { return tracks.map(function (_a) {
    var id = _a.id;
    return ({ id: id });
}); };
var getById = function (trackId) {
    return function (_a) {
        var id = _a.id;
        return id.toString() === trackId.toString();
    };
};
var getLikes = function (authHeader) {
    likesPromise =
        likesPromise ||
            lib_1.request({
                // url: `https://api-v2.soundcloud.com/users/108199/likes`,
                url: "/me/favorites",
                query: { limit: 200 },
                authHeader: authHeader,
            });
    return likesPromise;
};
var getPlaylists = function (authHeader) {
    playlistsPromise =
        playlistsPromise ||
            lib_1.request({
                url: "/me/playlists",
                // query: {representation: 'id'},
                authHeader: authHeader,
            });
    return playlistsPromise;
};
var getLikesAndPlaylists = function (authHeader) { return __awaiter(void 0, void 0, void 0, function () {
    var playlists, seen, processed, res;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, getPlaylists(authHeader)];
            case 1:
                playlists = _b.sent();
                seen = lib_1.getSeenPlaylist(playlists);
                processed = lib_1.getProcessedPlaylist(playlists);
                _a = {
                    seen: seen,
                    processed: processed,
                    playlists: playlists
                };
                return [4 /*yield*/, getLikes(authHeader)];
            case 2:
                res = (_a.likes = _b.sent(),
                    _a);
                if (!seen) {
                    throw new Error('Seen playlist not found');
                }
                if (!processed) {
                    throw new Error('Processed playlist not found');
                }
                return [2 /*return*/, res];
        }
    });
}); };
var getStream = function (_a) {
    var nextCursor = _a.nextCursor, futureCursor = _a.futureCursor, limit = _a.limit, authHeader = _a.authHeader;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, getLikesAndPlaylists(authHeader)];
                case 1:
                    _b.sent();
                    return [2 /*return*/, lib_1.request({
                            url: '/me/activities',
                            // url: 'https://api-v2.soundcloud.com/stream',
                            query: { cursor: nextCursor, 'uuid[to]': futureCursor, limit: limit },
                            authHeader: authHeader,
                        })];
            }
        });
    });
};
exports.default = {
    UserInterface: {
        __resolveType: function (obj, context, info) { return (obj.full_name ? 'User' : 'UserShort'); },
    },
    PlaylistInterface: {
        __resolveType: function (obj, context, info) { return (obj.description ? 'Playlist' : 'PlaylistShort'); },
    },
    Track: {
        id: function (source) { return source.id.toString(); },
        is_liked: function (source, vars, _a) {
            var authHeader = _a.authHeader;
            return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, getLikesAndPlaylists(authHeader)];
                    case 1: return [2 /*return*/, (_b.sent()).likes.some(getById(source.id))];
                }
            }); });
        },
        is_seen: function (source, vars, _a) {
            var authHeader = _a.authHeader;
            return __awaiter(void 0, void 0, void 0, function () { var _b, _c; return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _b = lib_1.isTrackInPlaylist;
                        _c = [source];
                        return [4 /*yield*/, getLikesAndPlaylists(authHeader)];
                    case 1: return [2 /*return*/, _b.apply(void 0, _c.concat([(_d.sent()).seen]))];
                }
            }); });
        },
        is_processed: function (source, vars, _a) {
            var authHeader = _a.authHeader;
            return __awaiter(void 0, void 0, void 0, function () { var _b, _c; return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _b = lib_1.isTrackInPlaylist;
                        _c = [source];
                        return [4 /*yield*/, getLikesAndPlaylists(authHeader)];
                    case 1: return [2 /*return*/, _b.apply(void 0, _c.concat([(_d.sent()).processed]))];
                }
            }); });
        },
        state: function (source) { return convertId(source.state); },
        license: function (source) { return convertId(source.license); },
        track_type: function (source) { return convertId(source.track_type); },
        is_duplicate: function (source) { return false; },
    },
    User: {
        id: function (source) { return source.id && source.id.toString(); },
    },
    UserShort: {
        // AKA label
        id: function (source) { return source.id && source.id.toString(); },
    },
    Playlist: {
        playlist_type: function (source) { return source.playlist_type && convertId(source.playlist_type); },
    },
    Activity: {
        next_cursor: function (source) { return getQuery(source.next_href).get('cursor'); },
        future_cursor: function (source) { return getQuery(source.future_href).get('uuid[to]'); },
    },
    Query: {
        stream: function (source, _a, _b) {
            var nextCursor = _a.nextCursor, futureCursor = _a.futureCursor, limit = _a.limit;
            var authHeader = _b.authHeader;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_c) {
                    return [2 /*return*/, getStream({
                            nextCursor: nextCursor,
                            futureCursor: futureCursor,
                            limit: limit,
                            authHeader: authHeader,
                        })];
                });
            });
        },
        likes: function (source, args, _a) {
            var authHeader = _a.authHeader, req = _a.req;
            return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, getLikesAndPlaylists(authHeader)];
                    case 1: return [2 /*return*/, (_b.sent()).likes];
                }
            }); });
        },
        me: function (source, args, _a) {
            var authHeader = _a.authHeader;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    return [2 /*return*/, lib_1.request({
                            url: "/me",
                            authHeader: authHeader,
                        })];
                });
            });
        },
        playlists: function (source, args, _a) {
            var authHeader = _a.authHeader;
            return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_b) {
                return [2 /*return*/, getPlaylists(authHeader)];
            }); });
        },
    },
    Mutation: {
        setLike: function (source, _a, _b) {
            var trackId = _a.trackId, state = _a.state;
            var authHeader = _b.authHeader;
            return __awaiter(void 0, void 0, void 0, function () {
                var getLikedTrack, likedTrack, _c, _d;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            getLikedTrack = function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, getLikesAndPlaylists(authHeader)];
                                    case 1: return [2 /*return*/, (_a.sent()).likes.find(getById(trackId))];
                                }
                            }); }); };
                            if (!!state) return [3 /*break*/, 2];
                            return [4 /*yield*/, getLikedTrack()];
                        case 1:
                            _c = _e.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            _c = null;
                            _e.label = 3;
                        case 3:
                            likedTrack = _c;
                            return [4 /*yield*/, lib_1.request({
                                    method: state ? 'POST' : 'DELETE',
                                    url: "/likes/tracks/" + trackId,
                                    authHeader: authHeader,
                                })];
                        case 4:
                            _e.sent();
                            likesPromise = null; // reset cache
                            _d = likedTrack;
                            if (_d) return [3 /*break*/, 6];
                            return [4 /*yield*/, getLikedTrack()];
                        case 5:
                            _d = (_e.sent());
                            _e.label = 6;
                        case 6: // reset cache
                        return [2 /*return*/, _d]; // if it was not there now it's available
                    }
                });
            });
        },
        setPlaylist: function (source, _a, _b) {
            var trackId = _a.trackId, type = _a.type, state = _a.state;
            var authHeader = _b.authHeader;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_c) {
                    return [2 /*return*/, mutex.exec(function () { return __awaiter(void 0, void 0, void 0, function () {
                            var things, playlistId_1, playlist, tracks, track, byId_1, updatedPlaylist_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, , 3, 4]);
                                        console.group('Executing', trackId, type, state);
                                        return [4 /*yield*/, getLikesAndPlaylists(authHeader)];
                                    case 1:
                                        things = _a.sent();
                                        playlistId_1 = (type === 'seen' ? things.seen : things.processed).id;
                                        playlist = things.playlists.find(function (p) { return p.id === playlistId_1; });
                                        tracks = playlist.tracks;
                                        track = tracks.find(getById(trackId));
                                        if (state) {
                                            tracks.push({ id: trackId });
                                            if (tracks.length > maxEntries) {
                                                tracks = tracks.slice(tracks.length - maxEntries, tracks.length);
                                            }
                                        }
                                        else {
                                            byId_1 = getById(trackId);
                                            tracks = tracks.filter(function (t) { return !byId_1(t); });
                                        }
                                        return [4 /*yield*/, lib_1.request({
                                                method: 'PUT',
                                                url: "/playlists/" + playlistId_1,
                                                body: {
                                                    playlist: {
                                                        tracks: simplifyTracks(tracks),
                                                    },
                                                },
                                                authHeader: authHeader,
                                            })];
                                    case 2:
                                        updatedPlaylist_1 = _a.sent();
                                        // update cache
                                        playlistsPromise = Promise.resolve(things.playlists.map(function (p) { return (p.id === playlistId_1 ? updatedPlaylist_1 : p); }));
                                        if (!track) {
                                            track = updatedPlaylist_1.tracks.find(getById(trackId));
                                        }
                                        return [2 /*return*/, track];
                                    case 3:
                                        console.groupEnd();
                                        return [7 /*endfinally*/];
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); })];
                });
            });
        },
        trimPlaylist: function (source, _a, _b) {
            var trackId = _a.trackId, type = _a.type, state = _a.state;
            var authHeader = _b.authHeader;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_c) {
                    return [2 /*return*/, mutex.exec(function () { return __awaiter(void 0, void 0, void 0, function () {
                            var things, playlistId, playlist, tracks, updatedPlaylist;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, getLikesAndPlaylists(authHeader)];
                                    case 1:
                                        things = _a.sent();
                                        playlistId = (type === 'seen' ? things.seen : things.processed).id;
                                        playlist = things.playlists.find(function (p) { return p.id === playlistId; });
                                        tracks = playlist.tracks.slice(playlist.tracks.length - maxEntries, playlist.tracks.length);
                                        return [4 /*yield*/, lib_1.request({
                                                method: 'PUT',
                                                url: "/playlists/" + playlistId,
                                                body: {
                                                    playlist: {
                                                        tracks: simplifyTracks(tracks),
                                                    },
                                                },
                                                authHeader: authHeader,
                                            })];
                                    case 2:
                                        updatedPlaylist = _a.sent();
                                        // update cache
                                        playlistsPromise = Promise.resolve(things.playlists.map(function (p) { return (p.id === playlistId ? updatedPlaylist : p); }));
                                        return [2 /*return*/, updatedPlaylist];
                                }
                            });
                        }); })];
                });
            });
        },
        createPlaylist: function (source, _a, _b) {
            var title = _a.title;
            var authHeader = _b.authHeader;
            return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, lib_1.request({
                                method: 'POST',
                                url: "/me/playlists",
                                body: {
                                    playlist: {
                                        title: title,
                                        embeddable_by: 'me',
                                        sharing: 'private',
                                        tracks: [],
                                    },
                                },
                                authHeader: authHeader,
                            })];
                        case 1: return [2 /*return*/, _c.sent()];
                    }
                });
            });
        },
    },
    ActivityItem: {
        origin_playlist: function (item, vars, _a) {
            var authHeader = _a.authHeader;
            return __awaiter(void 0, void 0, void 0, function () {
                var _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!(item.type.includes('playlist') && item.origin) // a guard against crappy reposts
                            ) return [3 /*break*/, 2]; // a guard against crappy reposts
                            return [4 /*yield*/, lib_1.request({
                                    url: "/playlists/" + item.origin.id,
                                    query: { limit: limit },
                                    authHeader: authHeader,
                                })];
                        case 1:
                            _b = _c.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            _b = null;
                            _c.label = 3;
                        case 3: return [2 /*return*/, _b];
                    }
                });
            });
        },
        origin_track: function (item) { return (~item.type.indexOf('track') && item.origin ? item.origin : null); },
        type: function (source) { return convertId(source.type); },
    },
};
