import React, {FunctionComponent} from 'react';
import {useGetRedirectData, openSoundCloudLogin, withAuth} from '../lib/login';

const LoginForm: FunctionComponent = () => {
    const {error, error_description, state} = useGetRedirectData();

    return (
        <div className="row justify-content-sm-center">
            <div className="col-12 col-sm-6">
                <div className="card">
                    <h2 className="card-header text-center">Login With SoundCloud</h2>
                    <div className="card-body text-center">
                        {error && <p className="text-danger">{error_description || error}</p>}
                        <button className="btn btn-warning btn-xl" onClick={() => openSoundCloudLogin(state)}>
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withAuth()(LoginForm);
