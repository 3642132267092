var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var graphql_1 = require("graphql");
function filterExtensionDefinitions(ast) {
    var extensionDefs = ast.definitions.filter(function (def) {
        return def.kind !== graphql_1.Kind.OBJECT_TYPE_EXTENSION &&
            def.kind !== graphql_1.Kind.INTERFACE_TYPE_EXTENSION &&
            def.kind !== graphql_1.Kind.INPUT_OBJECT_TYPE_EXTENSION &&
            def.kind !== graphql_1.Kind.UNION_TYPE_EXTENSION &&
            def.kind !== graphql_1.Kind.ENUM_TYPE_EXTENSION &&
            def.kind !== graphql_1.Kind.SCALAR_TYPE_EXTENSION &&
            def.kind !== graphql_1.Kind.SCHEMA_EXTENSION;
    });
    return __assign(__assign({}, ast), { definitions: extensionDefs });
}
exports.default = filterExtensionDefinitions;
