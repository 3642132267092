import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';

const NoMatch: FC = () => {
    const {pathname} = useLocation();
    return (
        <h3>
            No match for <code>{pathname}</code>
        </h3>
    );
};

export default NoMatch;
