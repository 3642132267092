import React, {FunctionComponent, useEffect, createRef} from 'react';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faTimes, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import classes from './Player.module.css';

export interface PlayerProps {
    prevId: any;
    nextId: any;
    currentId: any;
}

const EVENT = 'keydown';
const KEY_UP = '38';
const KEY_DOWN = '40';

const Player: FunctionComponent<PlayerProps> = ({currentId, prevId, nextId}) => {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const frame = createRef<HTMLIFrameElement>();

    const prevUrl = prevId && `${pathname}?id=${prevId}`;
    const nextUrl = nextId && `${pathname}?id=${nextId}`;

    useEffect(() => {
        const listener = (e) => {
            let newUrl;
            if (e.keyCode.toString() === KEY_UP && prevUrl) {
                newUrl = prevUrl;
            } else if (e.keyCode.toString() === KEY_DOWN && nextUrl) {
                newUrl = nextUrl;
            }

            if (newUrl) {
                e.preventDefault();
                navigate(newUrl);
            }
        };

        document.addEventListener(EVENT, listener);

        return () => {
            document.removeEventListener(EVENT, listener);
        };
    }, [navigate, nextUrl, prevUrl, pathname]);

    //FIXME Find a better way, store last focused element does not work btw
    useEffect(() => {
        const interval = setInterval(() => {
            if (document.activeElement === frame.current) {
                window.focus();
            }
        }, 250);
        return () => {
            clearInterval(interval);
        };
    }, [frame]);

    return (
        <div className={classes.player}>
            <div className={classes.prev}>
                {prevUrl && (
                    <Link to={prevUrl}>
                        <Icon icon={faChevronLeft} />
                    </Link>
                )}
            </div>
            <div className={classes.embed}>
                <Link to={pathname} className={`btn btn-danger ${classes.close}`}>
                    <Icon icon={faTimes} /> Close Player
                </Link>
                <iframe
                    ref={frame}
                    src={`https://w.soundcloud.com/player/?url=https%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F${currentId}&show_artwork=true&auto_play=true&show_comments=false&buying=false&sharing=false`}
                    title="Player"
                />
            </div>
            <div className={classes.next}>
                {nextUrl && (
                    <Link to={nextUrl}>
                        <Icon icon={faChevronRight} />
                    </Link>
                )}
            </div>
        </div>
    );
};

export default Player;
