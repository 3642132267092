import {useSetLikeMutation, useSetPlaylistMutation, Track} from 'soundcloud-tracker-schema';
import React, {useEffect, useMemo, useRef} from 'react';
import cogoToast from 'cogo-toast';
import cx from 'classnames';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faSpinner, IconDefinition} from '@fortawesome/free-solid-svg-icons';

interface ActionButtonProps {
    track: Track;
    icon: IconDefinition;
    activeClass: string;
    disabled: boolean;
}

export const SeenButton = ({track, isCurrent, ...props}: ActionButtonProps & {isCurrent: boolean}) => {
    const [exec, {loading}] = useSetPlaylistMutation();
    const variables = useMemo(() => ({trackId: track.id, type: 'seen'}), [track.id]);
    const hadError = useRef(false);

    // Auto-mark as seen
    useEffect(() => {
        if (!isCurrent) {
            return;
        }
        if (track.is_seen || loading || hadError.current) {
            return;
        }
        (async () => {
            try {
                await exec({variables: {...variables, state: true}});
            } catch (e) {
                console.error(e);
                cogoToast.error(e.message);
                hadError.current = true; // prevent endless retry
            }
        })();
    }, [variables, exec, track.is_seen, loading, isCurrent]);

    return <ActionButton state={track.is_seen} variables={variables} exec={exec} loading={loading} {...props} />;
};
export const ProcessedButton = ({track, ...props}: ActionButtonProps) => {
    const [exec, {loading}] = useSetPlaylistMutation();
    const variables = useMemo(() => ({trackId: track.id, type: 'processed'}), [track.id]);
    return <ActionButton state={track.is_processed} variables={variables} exec={exec} loading={loading} {...props} />;
};
export const LikeButton = ({track, ...props}: ActionButtonProps) => {
    const [exec, {loading}] = useSetLikeMutation();
    const variables = useMemo(() => ({trackId: track.id}), [track.id]);
    return <ActionButton state={track.is_liked} variables={variables} exec={exec} loading={loading} {...props} />;
};
const ActionButton = ({disabled, variables, exec, loading, activeClass, state, icon}) => (
    <button
        disabled={loading || disabled}
        className={cx('btn', {
            [`btn-${activeClass} active`]: state,
            'btn-secondary': !state,
        })}
        onClick={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            try {
                await exec({variables: {...variables, state: !state}});
            } catch (e) {
                console.error(e);
                cogoToast.error(e.message);
            }
        }}
    >
        {loading ? <Icon spin icon={faSpinner} /> : <Icon icon={icon} />}
    </button>
);
