import React, {FunctionComponent} from 'react';
import Stream from '../components/Stream';
import {useStream} from 'soundcloud-tracker-schema';

const Index: FunctionComponent = () => {
    const {error, data: {stream = undefined} = {}, loading, loadNextStream} = useStream();

    return error ? (
        <div>Error! {error.message}</div>
    ) : (
        <Stream stream={stream as any} loading={loading} loadNextStream={loadNextStream} />
    );
};

export default Index;
