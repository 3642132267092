import React, {FunctionComponent, memo, useCallback, useContext, useRef} from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';
import {faCheck, faExternalLinkAlt, faEye, faHeart} from '@fortawesome/free-solid-svg-icons';
import {formatDuration, isSet, Track} from 'soundcloud-tracker-schema';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import classes from './Track.module.css';
import streamClasses from './Stream.module.css';
import {copy} from '../lib';
import Image from './Image';
import {StreamContext} from './StreamContext';
import {LikeButton, ProcessedButton, SeenButton} from './ActionButton';

interface TrackProps {
    track: Track;
    isInPlaylist?: boolean;
}

const getTrackName = (track: Track) => [track.user?.username, track.title].filter(Boolean).join(' - ');

const labelRegex =
    /(Monstercat|Pilot\.|YANA Music|Drum&BassArena|UKF|TechniqueRecordings|RAM Records|Addictive-Behaviour|Audio Addict Records|Skankandbass|Metalheadz|DEM Recordings|Hospital Records|Data Transmission Drum & Bass) - /gi;

const getCorrectedTrackName = (track: Track) =>
    getTrackName(track).replaceAll('ft.', 'feat.').replaceAll(' x ', ' feat. ').replaceAll(labelRegex, '');

const TrackCmp: FunctionComponent<TrackProps> = ({track, isInPlaylist = false}) => {
    const titleRef = useRef(null);
    const {setHighlight, currentId, highlightId} = useContext(StreamContext);
    const isCurrent = track.id === currentId && !track.is_duplicate;
    const isHighlighted = track.id === highlightId;

    const className = cx([streamClasses.entry, classes.track], {
        [classes.current]: isCurrent,
        [classes.selected]: isHighlighted,
        [classes.inPlaylist]: isInPlaylist,
        [classes.duplicate]: track.is_duplicate,
    });

    const copyTrackTitle = useCallback((e) => {
        e.preventDefault();
        copy(titleRef.current);
    }, []);

    if (!track.title) {
        return <div className={className}>Track {track.id} is corrupted</div>;
    }

    return (
        <div className={className} onClick={() => setHighlight(track.id)}>
            <Image src={track.artwork_url} duplicate={track.is_duplicate} />

            <div className={classes.title}>
                {track.is_duplicate ? (
                    getCorrectedTrackName(track)
                ) : (
                    <Link to={`?id=${track.id}`} ref={titleRef}>
                        {getCorrectedTrackName(track)}
                    </Link>
                )}

                <a href={track.permalink_url} className={classes.permalink} target="_blank" rel="noopener noreferrer">
                    <Icon icon={faExternalLinkAlt} />
                </a>

                {isSet(track.duration) && <span className={cx(classes.badge, 'badge', 'bg-warning')}>SET</span>}
                {track.is_duplicate && <span className={cx(classes.badge, 'badge', 'bg-secondary')}>DUPLICATE</span>}
            </div>

            <div className={classes.duration}>{formatDuration(track.duration)}</div>

            <div className={classes.actions}>
                {!track.is_duplicate && (
                    <div className="btn-group btn-group-sm">
                        <SeenButton
                            disabled={track.is_duplicate}
                            track={track}
                            activeClass="info"
                            icon={faEye}
                            isCurrent={isCurrent}
                        />

                        <LikeButton disabled={track.is_duplicate} track={track} activeClass="warning" icon={faHeart} />

                        <ProcessedButton
                            disabled={track.is_duplicate}
                            track={track}
                            activeClass="success"
                            icon={faCheck}
                        />

                        <button className="btn btn-secondary" onClick={copyTrackTitle}>
                            Copy
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(TrackCmp);
