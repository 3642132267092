import React, {FunctionComponent, useCallback, useEffect, useMemo, useReducer, useRef} from 'react';
import cx from 'classnames';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {useLocation} from 'react-router-dom';
import {processCollection, ActivityItem} from 'soundcloud-tracker-schema';
import {useInView} from 'react-intersection-observer';
import {useVirtual} from 'react-virtual';
import {getCurrentId} from '../lib';
import {StreamContext} from './StreamContext';
import classes from './Stream.module.css';
import Player from './Player';
import Playlist from './Playlist';
import Track from './Track';

const StreamItem = ({item}: {item: ActivityItem}) => {
    if (item.origin_playlist) {
        return <Playlist playlist={item.origin_playlist} />;
    } else if (item.origin_track) {
        return <Track track={item.origin_track} />;
    }
    return <div className={cx(classes.entry, 'text-danger')}>Problem with stream item: {JSON.stringify(item)}</div>;
};

export interface StreamProps {
    stream: ActivityItem[];
    loadNextStream?: any;
    loading: boolean;
}

const useHighlight = () => {
    const [highlightIndex, highlightDispatch] = useReducer((state, {payload}) => {
        if (state === payload) {
            return null;
        }
        return payload;
    }, null);

    const setHighlight = useCallback((index) => highlightDispatch({payload: index}), []);
    return [highlightIndex, setHighlight];
};

const Stream: FunctionComponent<StreamProps> = ({
    stream: {collection = [], next_cursor = ''} = {} as any, //FIXME
    loadNextStream,
    loading,
}) => {
    const location = useLocation();

    const [highlightId, setHighlight] = useHighlight();

    const {processedCollection, flatCollection} = useMemo(() => processCollection(collection), [collection]);

    const currentId = getCurrentId(location);
    const currentIndex = flatCollection.findIndex(({id}) => id === currentId);

    const size = processedCollection.length;

    const parentRef = useRef();

    const {totalSize, virtualItems} = useVirtual({
        size,
        parentRef,
        overscan: 10,
    });

    const [endRef, inView] = useInView({trackVisibility: true, delay: 100});

    useEffect(() => {
        console.log({loading, inView});
        if (!loading && inView) {
            loadNextStream();
        }
    }, [loading, size, inView, loadNextStream]);

    const loadingCmp = (
        <>
            <Icon spin icon={faSpinner} />
            &nbsp;Loading
        </>
    );

    return (
        <>
            <div className={classes.container} ref={parentRef}>
                <StreamContext.Provider value={{highlightId, setHighlight, currentId}}>
                    <div
                        style={{
                            height: totalSize + 'px',
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        {virtualItems.map(({index, measureRef, start}) => (
                            <div
                                key={index}
                                ref={measureRef}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    transform: `translateY(${start}px)`,
                                }}
                            >
                                <StreamItem item={processedCollection[index]} />
                            </div>
                        ))}
                    </div>
                </StreamContext.Provider>
                {!loading && (
                    <div className={cx(classes.entry, classes.loading)} ref={endRef}>
                        {loadingCmp}
                    </div>
                )}
            </div>

            {loading && <div className={cx(classes.entry, classes.loading)}>{loadingCmp}</div>}

            {currentId && (
                <Player
                    currentId={currentId}
                    nextId={flatCollection[currentIndex + 1]?.id}
                    prevId={flatCollection[currentIndex - 1]?.id}
                />
            )}
        </>
    );
};

// Playlists presence is guaranteed at LoggedInWrapper
export default Stream;
