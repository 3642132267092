"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreatePlaylist = exports.useStream = exports.Provider = void 0;
var types_1 = require("./types");
var client_1 = require("@apollo/client");
Object.defineProperty(exports, "Provider", { enumerable: true, get: function () { return client_1.ApolloProvider; } });
var rewriteStore = function (_a) {
    var store = _a.store, query = _a.query, processData = _a.processData, _b = _a.variables, variables = _b === void 0 ? null : _b;
    return store.writeQuery({
        query: query,
        variables: variables,
        data: processData(store.readQuery({ query: query, variables: variables })),
    });
};
exports.useStream = function () {
    var _a = types_1.useGetStreamQuery({
        notifyOnNetworkStatusChange: true,
    }), loading = _a.loading, data = _a.data, error = _a.error, fetchMore = _a.fetchMore;
    return {
        loading: loading,
        data: data,
        error: error,
        loadNextStream: function () {
            return fetchMore({
                variables: {
                    nextCursor: data.stream.next_cursor,
                },
            });
        },
        loadFutureStream: function () {
            return fetchMore({
                variables: {
                    futureCursor: data.stream.future_cursor,
                },
            });
        },
    };
};
exports.useCreatePlaylist = function () {
    var _a = types_1.useCreatePlaylistMutation(), exec = _a[0], mutation = _a[1];
    return [
        exec,
        __assign(__assign({}, mutation), { update: function (store, _a) {
                var createPlaylist = _a.data.createPlaylist;
                if (!createPlaylist) {
                    return;
                }
                rewriteStore({
                    store: store,
                    query: types_1.GetPlaylistsDocument,
                    processData: function (data) { return (__assign(__assign({}, data), { getPlaylists: __spreadArrays(data.getPlaylists, [createPlaylist]) })); },
                });
            } }),
    ];
};
